//
// Buttons Base
//

.btn {
	outline: none !important;	

	&:not(.btn-shadow):not(.shadow):not(.shadow-sm):not(.shadow-lg) {
		box-shadow: none !important;
	}

	&:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
		border: 0;
		padding: calc(#{$btn-padding-y} + #{$btn-border-width}) calc(#{$btn-padding-x} + #{$btn-border-width});

		&.btn-lg {
			padding: calc(#{$btn-padding-y-lg} + #{$btn-border-width}) calc(#{$btn-padding-x-lg} + #{$btn-border-width});
		}

		&.btn-sm {
			padding: calc(#{$btn-padding-y-sm} + #{$btn-border-width}) calc(#{$btn-padding-x-sm} + #{$btn-border-width});
		}
	}

	&.btn-link {
		border: 0;
		border-radius: 0;
		padding-left: 0 !important;
		padding-right: 0 !important;
		text-decoration: none;
		font-weight: $btn-font-weight;
	}

	&.btn-outline-dashed {
		border: 1px dashed $border-dashed-color;
	}

	&.btn-outline-default {
		border: 1px solid $input-border-color;
	}

	&.btn-flush {
		@include button-reset();
	}

	&.btn-flex {		
		display: inline-flex;
		align-items: center;
	}
}

.btn {
	i {
		display: inline-flex;
		font-size: $font-size-base;
		padding-right: 0.35rem;
		vertical-align: middle;
		line-height: 0;
	}

	.svg-icon {
		flex-shrink: 0;
		line-height: 0;
		margin-right: 0.5rem;
	}

	&.btn-icon {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 0;
		height: $input-height;
		width: $input-height;

		&:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
			border: 0;
		}

		&.btn-sm {
			height: $input-height-sm;
			width: $input-height-sm;
		}

		&.btn-lg {
			height: $input-height-lg;
			width: $input-height-lg;
		}

		&.btn-circle {
			border-radius: 50%;
		}

		i,
		.svg-icon {
			padding: 0;
			margin: 0;
			line-height: 1;
		}

		&.btn-icon-menu {
			font-size: 0.75rem;
			font-weight: 900;
			height: 40px;
			width: 180px;

			@include media-breakpoint-down(lg) {
				font-size: 0.725rem;
				font-weight: 850;
				height: 45px;
				width: 165px;
			}

			@include media-breakpoint-down(md) {
				font-size: 0.7rem;
				font-weight: 800;
				height: 50px;
				width: 150px;
			}
		}
	}
}

.btn.btn-hover-rise {
	transition: transform 0.3s ease;

	&:hover {
		transform: translateY(-10%);
		transition: transform 0.3s ease;
	}
}

.btn.btn-hover-scale {
	transition: transform 0.3s ease;

	&:hover {
		transform: scale(1.1);
		transition: transform 0.3s ease;
	}
}

.btn.btn-hover-rotate-end {
	transition: transform 0.3s ease;

	&:hover {
		transform: rotate(4deg);
		transition: transform 0.3s ease;
	}
}

.btn.btn-hover-rotate-start {
	transition: transform 0.3s ease;

	&:hover {
		transform: rotate(-4deg);
		transition: transform 0.3s ease;
	}
}
