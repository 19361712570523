#container-aside-scroll {
  height: calc(100% - (80px + 45px));
}

#dropdown-languages {
  position: fixed;
  inset: auto auto 0px 0px;
  margin: 0px;
  transform: translate3d(65px, -157.5px, 0px);

  #background-dropdown-header {
    background-image: url(../assets/images/themes/image-background.png);
  }
}

#dropdown-user-profile {
  position: fixed;
  inset: auto auto 0px 0px;
  margin: 0px;
  transform: translate(75px, -95.5px);
}

#modal-identification-view-container {
  height: 85vh;
  width: auto;
}

#modal-form-view-container {
  height: 85vh;
  width: auto;
}

