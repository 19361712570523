@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
@import url(assets/fonts/poppins.css);

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media (min-width: 768px) {
  .border-md-0 {
    border-bottom: none !important;
  }
}

html body { 
  padding: 0px;
  margin: 0px;
}

body {
  font-family: Poppins, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app {
  width: 100%;
  height: 100%;
}

.cursor-pointer-none {
  cursor: auto !important;
}

.text-justify {
  text-align: justify;
}

.dropdown-toggle::after {
  content: none;
}

.rti--container {
  padding: 0;
  margin-bottom: 0.5rem;
  border: none !important;
  background-color: #F5F8FA;
  transition: color 0.2s ease, background-color 0.2s ease;
}

.rti--container:active,
.rti--container.active,
.rti--container:focus,
.rti--container:focus-within {
  border-color: inherit !important;
  box-shadow: none !important;
  background-color: #eef3f7;
  border-color: #eef3f7;
  transition: color 0.2s ease, background-color 0.2s ease;
}

.tag-cls {
  white-space: pre-wrap;
  padding: 0.5rem 0 0.5rem 0.75rem;
  margin: 0.5rem 0.5rem 0;
}

.input-cls {
  width: 100%;
}

.template-model {
  overflow: hidden;
  height: 100px;
  width: 100px;
  position: relative;
  cursor: pointer;
  margin: 0 15px;
  box-shadow: 0 0 25px 1px rgba(0, 0, 0, .3);
  transition: .5s;
  background-color: #192440
}

.template-model:after {
  content: '';
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  width: 500px;
  height: 500px;
  transform: translate(-140%, -50%);
  background-color: #8950FC;
  opacity: 0.8;
  border-radius: 50%;
  transition: .8s
}

.template-model:hover:after {
  transform: translate(-50%, -50%)
}

.template-model:hover img {
  transform: translate(-50%, -50%) scale(1.3) rotate(20deg)
}

.template-model-image {
  position: absolute;
  height: 110%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: .8s
}

.template-model-label {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-2000px, -50%);
  color: #FFFFFF;
  transition: .8s;
  transition-timing-function: ease-in
}

.template-model:hover label {
  transform: translate(-50%, -50%);
  transition-timing-function: ease
}

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #192440;
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

.load-container {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
