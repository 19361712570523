//
// Main
//

body {
	background-color: $page-bg;
}

.text-page-bg {
	color: $page-bg;
}

@include media-breakpoint-up(lg) {
	.container,
	.container-xxl,
	.container-fluid {
		padding: 0 get($content-spacing, desktop);
	}

	.wrapper {
		transition: padding-left get($aside-config, transition-speed) ease,  margin-right get($aside-config, transition-speed) ease;

		.aside-secondary-enabled.aside-fixed & {
			transition: padding-left get($aside-config, transition-speed) ease;
			padding-left: get($aside-config, width, desktop);
		}

		.aside-secondary-enabled.aside-fixed[data-kt-aside-minimize="on"] & {
			transition: padding-left get($aside-config, transition-speed) ease;
			padding-left: get($aside-config, primary-width, desktop);
		}

		.aside-secondary-disabled.aside-fixed & {
			transition: padding-left get($aside-config, transition-speed) ease;
			padding-left: get($aside-config, primary-width, desktop);			 
		}

		.header-fixed[data-kt-sticky-header="on"] & {
			padding-top: get($header-config, desktop, default, height);
		}
	}
}

@include media-breakpoint-down(lg) {
	.container,
	.container-xxl,
	.container-fluid {
		max-width: none;
		padding: 0 get($content-spacing, tablet-and-mobile);
	}

	.wrapper {
		.header-tablet-and-mobile-fixed[data-kt-sticky-header="on"] & {
			padding-top: get($header-config, tablet-and-mobile, fixed, height);
		}
	}
}
